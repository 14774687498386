
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getClaim } from "@/api/claim.api";
import { useRouter } from "vue-router";
import { Claim } from "@/modules/claims/claim.model";
import { useStore } from "vuex";
import { getDictionary, getRevenueDictionary } from "@/api/dictionaries.api";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { getPatientPayer } from "@/api/patientPayer.api";

export interface Data {
  isDirty: boolean;
  claim?: Claim;
  payers: any;
  dict: {
    patientPayerPriority: { code: string; description: string }[];
    patientRelationshipToSubscriber: { code: string; description: string }[];
  };
}

export default defineComponent({
  name: "EffectivePayersComponent",
  props: ["claim", "claimId"],
  components: {},
  setup(props, ctx) {
    const store = useStore();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      claim: undefined,
      payers: [],
      dict: {
        patientPayerPriority: [],
        patientRelationshipToSubscriber: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();

      data.dict.patientPayerPriority = store.getters.allPatientPayerPriority;
      data.dict.patientRelationshipToSubscriber = await getDictionary(10, "");

      let claim = props.claim;
      if (!claim) {
        claim = await getClaim(props.claimId);
      }
      data.claim = claim;
      if (data.claim) {
        if (data.claim.payer)
          data.payers.push(await getPatientPayer(data.claim.payer.id));
        if (data.claim.secondaryPayer)
          data.payers.push(await getPatientPayer(data.claim.secondaryPayer.id));
        if (data.claim.tertiaryPayer)
          data.payers.push(await getPatientPayer(data.claim.tertiaryPayer.id));
      }
    });

    function getPatientPayerPriority(code) {
      const item = data.dict.patientPayerPriority.find((item) => {
        return item.code == code;
      });
      if (item) {
        return item.description;
      }
      return code;
    }

    function getSubscriberName(item) {
      let name = item.subscriberFirstName;
      if (item.subscriberMiddleName) {
        name = name + " " + item.subscriberMiddleName;
      }
      name = name + " " + item.subscriberLastName;
      return name;
    }

    function getPatientRelationshipToSubscriber(code) {
      const item = data.dict.patientRelationshipToSubscriber.find((item) => {
        return item.code == code;
      });
      if (item) {
        return item.description;
      }
      return code;
    }

    return {
      getSubscriberName,
      getPatientPayerPriority,
      organizationId,
      getPatientRelationshipToSubscriber,
      data,
    };
  },
});
