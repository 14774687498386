import ApiService from "@/core/services/ApiService";

export async function getDictionary(dictionaryType, search: string) {
  const request = {
    DictionaryType: dictionaryType,
    Search: search,
  };
  return await ApiService.post(
    "/api/v1/Dictionaries/search",
    request as never
  ).then((res) => {
    return res.data;
  });
}

export async function getRevenueDictionary(search: string, type?: number) {
  const request = {
    Search: search,
    Type: type,
  };
  return await ApiService.post(
    "/api/v1/Dictionaries/searchRevenue",
    request as never
  ).then((res) => {
    return res.data;
  });
}

export async function getTypeOfBillCode() {
  return await ApiService.post(
    "/api/v1/TypeOfBillCode/searchDict", {} as any
  ).then((res) => {
    return res.data;
  });
}

export async function getClearinghouseStatuses() {
  return await ApiService.post(
    "/api/v1/ClearinghouseStatus/searchDict", {} as any
  ).then((res) => {
    return res.data;
  });
}


