import ApiService from "@/core/services/ApiService";
import { PatientPayer } from "@/modules/patientPayer/patientPayer.model";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/patientpayers";

export async function patientPayersByPatient(request) {
  return await ApiService.post(url + "/patientPayersByPatient", request as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function effectivePayers(request) {
  return await ApiService.post(url + "/effectivePayers", request as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchPatientPayers(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getPatientPayers() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if(item.effectiveDate)
          item.effectiveDate = new Date(item.effectiveDate);
        if(item.termDate)
          item.termDate = new Date(item.termDate);
      });
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addPatientPayer(patientPayer) {
  return await ApiService.post(url, patientPayer as never)
    .then()
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updatePatientPayer(patientPayer) {
  if (patientPayer) {
    return await ApiService.put(
      url + "/" + patientPayer.id,
      patientPayer as never
    )
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function getPatientPayer(patientPayerId): Promise<PatientPayer> {
  return await ApiService.get(url + "/" + patientPayerId)
    .then((res) => {
      const patientPayer = res.data;
      return patientPayer;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkPatientPayerPriority(patientPayer) {
  return await ApiService.post(url + "/checkPatientPayerPriority", patientPayer as never)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function deletePatientPayer(patientPayer) {
  if (patientPayer) {
    await ApiService.delete(url + "/" + patientPayer?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

// export async function getWaystarList(payerSearch: string) {
//   const request = {
//     payerSearch: payerSearch,
//   };
//   return await ApiService.post(
//     "/api/v1/parentinsurance/search",
//     request as never
//   )
//     .then((res) => {
//       return res.data;
//     })
//     .catch(({ response }) => {
//       processErrors(response);
//     });
// }
